import { Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Helmet } from 'react-helmet-async';

import { FullPageErrorBoundary } from '@components/ErrorBoundary';
import { isNemoSubdomain } from '@utils/url_manipulations';
import { RequireAuth, useAuthentication } from '@services/authentication_provider';
import { FeatureFlagsProvider, IfFlagEnabled } from '@services/feature_flag_provider';
import { RedirectUsingSearchParam } from '@services/redirect_parameter';
import { setLocale } from '@utils/validation';
import { NemoLoadingCentered } from '@components/Loading';
import { useUserContext } from '@services/user_provider';
import { UserProvider } from '@services/user_provider';
import { DocumentTitle } from '@components/DocumentTitle';
import { useTrackPosthogPageviewInSPA } from '@hooks/useTrackPosthogPageviewInSPA';
import NotionRedirect from '@pages/NotionRedirect';
import { useReloadOnVitePreloadError } from '@hooks/useReloadOnVitePreloadError';
import { DevModeProvider } from '@services/dev_mode_provider';
import { usePageView } from '@hooks/usePageView';

import { GlobalModalsContextProvider } from './context/GlobalModalsContext';

const AdminAuthenticateAsPage = lazy(() => import('@pages/Admin/AuthenticateAs'));
const RequestAccess = lazy(() => import('@pages/RequestAccess'));
const AdminPage = lazy(() => import('@pages/Admin'));
const AdminPublicRoundsPage = lazy(() => import('@pages/Admin/PublicRounds'));
const AdminInviteNewUserPage = lazy(() => import('@pages/Admin/InviteNewUser'));
const AdminSendInviteReminderPage = lazy(() => import('@pages/Admin/SendInviteReminder'));
const AdminBlockedUsersPage = lazy(() => import('@pages/Admin/BlockedUsers'));
const AdminPromptEngineeringPage = lazy(() => import('@pages/Admin/PromptEngineering'));
const AdminTestEmailsPage = lazy(() => import('@pages/Admin/TestEmails'));
const AdminPublicProfilesPage = lazy(() => import('@pages/Admin/Profiles'));
const AdminInviteProposalsPage = lazy(() => import('@pages/Admin/InviteProposals'));
const AdminMetricsPage = lazy(() => import('@pages/Admin/Metrics'));
const AdminEmailEventsPage = lazy(() => import('@pages/Admin/EmailEvents'));
const AdminDataAccessKeysPage = lazy(() => import('@pages/Admin/DataAccessKeys'));
const AdminLatestUsersPage = lazy(() => import('@pages/Admin/LatestUsers'));
const AdminInfoRequestsPage = lazy(() => import('@pages/Admin/InfoRequests'));
const AdminCuratedListsPage = lazy(() => import('@pages/Admin/CuratedLists'));
const AdminUserRecommendationListPage = lazy(() => import('@pages/Admin/UserRecommendationList'));
const AdminExploreDealsPage = lazy(() => import('@pages/Admin/ExploreDeals'));
const AdminAlexandriaPage = lazy(() => import('@pages/Admin/Alexandria'));
const CapTablePage = lazy(() => import('@pages/CapTable'));
const Connections = lazy(() => import('@pages/Connections'));
const CuratedListPage = lazy(() => import('@pages/Connections/CuratedList'));
const Deals = lazy(() => import('@pages/Deals'));
const DealsDigest = lazy(() => import('@pages/DealsDigest'));
const DealflowInsights = lazy(() => import('@pages/DealflowInsights'));
const Explore = lazy(() => import('@pages/Explore'));
const FancyCards = lazy(() => import('@pages/Cards'));
const Home = lazy(() => import('@pages/Home'));
const List = lazy(() => import('@pages/List'));
const PublicPortfoiliView = lazy(() => import('@pages/Portfolio/PublicView'));
const ProfilesManager = lazy(() => import('@pages/ProfilesManager'));
const LogoutRedirect = lazy(() => import('@pages/LogoutRedirect'));
const MessageRedirect = lazy(() => import('@pages/MessageRedirect'));
const WaitlistRedirect = lazy(() => import('@pages/WaitlistRedirect'));
const IntroRedirect = lazy(() => import('@pages/IntroRedirect'));
const Nemo = lazy(() => import('@pages/Nemo'));
const NemoInsights = lazy(() => import('@pages/Insights'));
const NetworkPage = lazy(() => import('@pages/Network'));
const NotFound = lazy(() => import('@pages/NotFound'));
const Activity = lazy(() => import('@pages/Activity'));
const Policy = lazy(() => import('@pages/PrivacyPolicy'));
const PortfolioCompany = lazy(() => import('@pages/PortfolioCompany'));
const PortfolioOverview = lazy(() => import('@pages/PortfolioOverview'));
const Portfolio = {
  Home: lazy(() => import('@pages/Portfolio')),
  Profile: lazy(() => import('@pages/Portfolio/Profile')),
  Views: {
    Investors: lazy(() => import('@pages/Portfolio/views/investors/view')),
    Organisations: lazy(() => import('@pages/Portfolio/views/organisations/tablev2')),
    InvestorUpdates: lazy(() => import('@pages/Portfolio/views/investorUpdates/view')),
    Rounds: lazy(() => import('@pages/Portfolio/views/organisations/tablev3')),
    OnAzava: lazy(() => import('@pages/Portfolio/views/onAzava/view')),
    People: lazy(() => import('@pages/Portfolio/views/people/view')),
  },
};
const PublicProfilePage = lazy(() => import('@pages/PublicProfiles'));
const SettingsPage = lazy(() => import('@pages/Settings'));
const SignUpFromAdmin = lazy(() => import('@pages/Onboarding/SignUpFromAdmin'));
const Terms = lazy(() => import('@pages/Terms'));
const UserRegistration = lazy(() => import('@pages/UserRegistration'));
const VerifyEmail = lazy(() => import('@pages/VerifyEmail'));
const ApplyToJoinVerifyEmail = lazy(() => import('@pages/VerifyJoinApplicationEmail'));
const VerifyInvite = lazy(() => import('@pages/VerifyInvite'));
const Layout = lazy(() => import('./Layout'));
const MagicLinkLanding = lazy(() => import('@pages/MagicLinkLanding'));
const MagicLinkSignup = lazy(() => import('@pages/MagicLinkSignup'));
const ProfilePage = lazy(() => import('@pages/Profile'));
const MyProfile = lazy(() => import('@pages/Profile/MyProfile'));
const SignIn = lazy(() => import('@pages/SignIn'));
const CompleteProfile = lazy(() => import('@pages/CompleteProfile'));
const Chat = lazy(() => import('@pages/Chat'));
const CometWelcome = lazy(() => import('@pages/Comet/CometWelcome'));
const CometAuth = lazy(() => import('@pages/Comet/CometAuth'));
const CometAuthCallback = lazy(() => import('@pages/Comet/CometAuthCallback'));

const DealsSubscription = lazy(() => import('@pages/Deals/hooks'));

setLocale();
const isNemo = isNemoSubdomain();

export function App() {
  const { isAuthenticated, isLoading } = useAuthentication();
  useTrackPosthogPageviewInSPA();
  usePageView();
  useReloadOnVitePreloadError();

  if (isLoading) {
    return null;
  }

  return (
    <FullPageErrorBoundary>
      <ToastContainer />
      <RedirectUsingSearchParam />
      <DocumentTitle title={'Azava'} />
      {import.meta.env.VITE_ENV === 'staging' ? (
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
      ) : null}
      <Suspense fallback={<NemoLoadingCentered />}>
        {isAuthenticated ? (
          <UserProvider>
            <FeatureFlagsProvider>
              <DevModeProvider>
                <GlobalModalsContextProvider>
                  <RequireAuth>
                    <DealsSubscription />
                    <AuthedApp />
                  </RequireAuth>
                </GlobalModalsContextProvider>
              </DevModeProvider>
            </FeatureFlagsProvider>
          </UserProvider>
        ) : (
          <UnAuthedApp />
        )}
      </Suspense>
    </FullPageErrorBoundary>
  );
}

function AuthedApp() {
  const { isPlatformAdmin, hasCompletedRegistration, publicProfile } = useUserContext();
  const completeProfile = <CompleteProfile />;

  if (isNemo) {
    return <NemoRoutes />;
  }

  if (!hasCompletedRegistration) {
    return <PreRegistrationRoutes />;
  }

  // Profile needs completing (usecase for legacy users)
  if (publicProfile && !publicProfile.descriptorsMiscTags.length) {
    return (
      <Routes>
        <Route path="/complete-profile" element={completeProfile} />
        <Route path="*" element={<Navigate to="/complete-profile" replace />} />
      </Routes>
    );
  }

  return (
    <Routes>
      {/* ROOT ROUTE */}
      <Route path="/" element={<Navigate replace to="/deals" />} />
      <Route path="/sign-in" element={<Navigate replace to="/deals" />} />
      <Route path="/sign-in-v2" element={<Navigate replace to="/deals" />} />

      <Route element={<Layout />}>
        {/* ADMIN */}
        {isPlatformAdmin ? <Route path="/admin/*" element={<AdminRoutes />} /> : null}

        <Route path="/deals" element={<Deals />} />
        <Route path="/explore" element={<Explore />} />
        <Route path="/explore/:slug" element={<Explore />} />
        <Route path="/activity" element={<Activity />} />
        <Route path="/messages/:id?" element={<Chat />} />
        <Route path="/settings" element={<SettingsPage />} />
        <Route
          path="/dealflow-insights"
          element={
            <IfFlagEnabled flag="DEALFLOW_INSIGHTS" fallback={<NotFound />}>
              <DealflowInsights />
            </IfFlagEnabled>
          }
        />
        <Route
          path="/portfolio"
          element={
            <IfFlagEnabled flag="PORTFOLIO" fallback={<NotFound />}>
              <Portfolio.Home />
            </IfFlagEnabled>
          }
        >
          {isPlatformAdmin ? <Route path="on-azava" element={<Portfolio.Views.OnAzava />} /> : null}
          <Route index element={<Portfolio.Views.Organisations />} />
          <Route path="organisations" element={<Portfolio.Views.Organisations />} />
          <Route path="rounds" element={<Portfolio.Views.Rounds />} />
          <Route path="investors" element={<Portfolio.Views.Investors />} />
          <Route path="profile/:name" element={<Portfolio.Profile />} />
          <Route path="investor-updates" element={<Portfolio.Views.InvestorUpdates />} />
        </Route>
        <Route path="/list/:id" element={<List />} />
        <Route path="/share/:slug" element={<List />} />
        <Route path="/d/:slug" element={<List />} />
        <Route path="/profile/:slug" element={<ProfilePage />} />
        <Route path="/profile/me" element={<MyProfile />} />
        <Route path="/profile/legacy/:name" element={<PublicProfilePage />} />
        <Route path="/deals-digest" element={<DealsDigest />} />
        <Route path="/connections" element={<Connections />} />
        <Route path="/connections/lists/:slug" element={<CuratedListPage />} />
        <Route path="/profiles-manager" element={<ProfilesManager />} />
        <Route path="/:name/view/:slug" element={<PublicPortfoiliView />} />
        <Route path="/notion-redirect" element={<NotionRedirect />} />
        <Route
          path="/valuations/:legalEntityId?"
          element={
            <IfFlagEnabled flag="PORTFOLIO" fallback={<NotFound />}>
              <PortfolioOverview />
            </IfFlagEnabled>
          }
        />
        <Route
          path="/valuations/:legalEntityId/investments/:portfolioLegalEntityId"
          element={
            <IfFlagEnabled flag="PORTFOLIO" fallback={<NotFound />}>
              <PortfolioCompany />
            </IfFlagEnabled>
          }
        />
        <Route
          path="/profile/:legalEntityId/captable"
          element={
            <IfFlagEnabled flag="PORTFOLIO" fallback={<NotFound />}>
              <CapTablePage />
            </IfFlagEnabled>
          }
        />
      </Route>
      <Route path="/complete-profile" element={completeProfile} />
      <Route path="/cards" element={<FancyCards />} />
      <Route path="/insights" element={<NemoInsights />} />
      <Route path="/intro/:id" element={<IntroRedirect />} />
      <Route path="/nemo" element={<Nemo />} />
      <Route path="/investors/:slug" element={<WaitlistRedirect />} />

      <Route
        path="/network/old"
        element={
          <IfFlagEnabled flag="COINVESTOR_NETWORK" fallback={<NotFound />}>
            <NetworkPage />
          </IfFlagEnabled>
        }
      />
      <Route path="/privacy" element={<Policy />} />

      <Route path="/share/message/:id" element={<MessageRedirect />} />
      <Route path="/terms" element={<Terms />} />

      {/* REDIRECT explicitly from paths for unregistered users */}
      <Route path="/welcome" element={<Navigate to="/deals" replace />} />
      <Route path="/register" element={<Navigate to="/deals" replace />} />

      {/* Global logout route */}
      <Route path="/logout" element={<LogoutRedirect />} />
      <Route element={<Layout />}>
        <Route path="/:slug" element={<ProfilePage fallback={<NotFound />} />} />
      </Route>
      {/* Comet routes */}
      <Route path="/extension" element={<CometWelcome />} />
      <Route path="/extension/auth/callback" element={<CometAuthCallback />} />
      <Route path="/extension/auth" element={<Navigate to="/extension" replace />} />
      {/* 404 NOT FOUND */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

function NemoRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Nemo />} />
      <Route element={<Layout />}>
        <Route path="/share/:slug" element={<List />} />
        <Route path="/d/:slug" element={<List />} />
      </Route>
    </Routes>
  );
}

function PreRegistrationRoutes() {
  return (
    <Routes>
      <Route path="/register/sign-up" element={<SignUpFromAdmin />} />
      <Route path="/welcome" element={<Navigate to="/register" replace />} />
      <Route path="/register" element={<UserRegistration />} />
      <Route path="*" element={<Navigate to="/register" replace />} />
    </Routes>
  );
}

function AdminRoutes() {
  return (
    <Routes>
      <Route path="" element={<AdminPage />}>
        <Route path="authenticate-as" element={<AdminAuthenticateAsPage />} />
        <Route path="public-rounds" element={<AdminPublicRoundsPage />} />
        <Route path="invite-new-user" element={<AdminInviteNewUserPage />} />
        <Route path="send-invite-reminder" element={<AdminSendInviteReminderPage />} />
        <Route path="blocked-users" element={<AdminBlockedUsersPage />} />
        <Route path="test-emails" element={<AdminTestEmailsPage />} />
        <Route path="profiles" element={<AdminPublicProfilesPage />} />
        <Route path="invite-proposals/:id" element={<AdminInviteProposalsPage />} />
        <Route path="invite-proposals" element={<AdminInviteProposalsPage />} />
        {import.meta.env.VITE_ENV === 'production' ? null : (
          <Route path="prompt-engineering" element={<AdminPromptEngineeringPage />} />
        )}
        <Route path="metrics" element={<AdminMetricsPage />} />
        <Route path="email-events" element={<AdminEmailEventsPage />} />
        <Route path="data-access-keys" element={<AdminDataAccessKeysPage />} />
        <Route path="latest-users" element={<AdminLatestUsersPage />} />
        <Route path="info-requests" element={<AdminInfoRequestsPage />} />
        <Route path="lists/:id?" element={<AdminCuratedListsPage />} />
        <Route path="user-recommendation-list/:id?" element={<AdminUserRecommendationListPage />} />
        <Route path="explore-deals" element={<AdminExploreDealsPage />} />
        <Route path="alexandria" element={<AdminAlexandriaPage />} />
      </Route>
    </Routes>
  );
}

function UnAuthedApp() {
  return (
    <Routes>
      <Route path="/" element={isNemo ? <Nemo /> : <Home />} />
      <Route path="/cards" element={<FancyCards />} />
      <Route path="/intro/:id" element={<IntroRedirect />} />
      <Route path="/list/:id" element={<List />} />
      <Route path="/:name/view/:slug" element={<PublicPortfoiliView />} />
      <Route path="/investors/:slug" element={<WaitlistRedirect />} />

      <Route path="/join/:token" element={<VerifyInvite />} />
      <Route path="/nemo" element={<Nemo />} />
      <Route path="/privacy" element={<Policy />} />
      <Route path="/sign-in-v2" element={<SignIn />} />
      <Route path="/profile/:slug" element={<ProfilePage />} />
      <Route path="/verify-email" element={<VerifyEmail />} />
      <Route path="/apply-to-join" element={<RequestAccess />} />
      <Route path="/apply-to-join/verify/:token" element={<ApplyToJoinVerifyEmail />} />
      <Route path="/magic/join" element={<MagicLinkSignup />} />
      <Route path="/magic" element={<MagicLinkLanding />} />
      <Route element={<Layout />}>
        <Route path="/share/:slug" element={<List />} />
        <Route path="/d/:slug" element={<List />} />
        <Route path="/connections/lists/:slug" element={<CuratedListPage />} />
      </Route>
      <Route path="/share/message/:id" element={<MessageRedirect />} />
      <Route path="/terms" element={<Terms />} />
      {/* Global logout route */}
      <Route path="/logout" element={<LogoutRedirect />} />
      <Route path="/:slug" element={<ProfilePage fallback={<Navigate to="/" />} />} />

      {/* Comet routes */}
      <Route path="/extension" element={<Navigate to="/extension/auth" replace />} />
      <Route path="/extension/auth" element={<CometAuth />} />
      {/* all other pages redirect to home */}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}
